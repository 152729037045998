import React from "react"

const ClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.5"
    height="18.5"
    viewBox="0 0 18.5 18.5"
  >
    <g id="clock" transform="translate(0.25 0.25)">
      <path
        id="Path_22015"
        data-name="Path 22015"
        d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0ZM9,16.875A7.875,7.875,0,1,1,16.875,9,7.884,7.884,0,0,1,9,16.875Z"
        fill={props.fill}
        stroke={props.fill}
        strokeWidth="0.5"
      />
      <path
        id="Path_22016"
        data-name="Path 22016"
        d="M208.919,83.118h-1.125v5.858l3.54,3.54.8-.8-3.21-3.21Z"
        transform="translate(-199.357 -79.743)"
        fill={props.fill}
        stroke={props.fill}
        strokeWidth="0.5"
      />
    </g>
  </svg>
)

ClockIcon.defaultProps = {
  fill: "#9b9ea2",
}

export default ClockIcon
