import React from 'react'

const AddressIcon = (props) =>(
	<svg xmlns="http://www.w3.org/2000/svg" width="24.005" height="32" viewBox="0 0 24.005 32"><path d="M-11117.73-171.271c-.462-.407-11.269-9.98-11.269-19.79a11.983 11.983 0 0 1 11.999-11.939 11.985 11.985 0 0 1 12 11.937c0 9.81-10.809 19.383-11.267 19.79a1.125 1.125 0 0 1-.738.273 1.1 1.1 0 0 1-.725-.271zm-9.047-19.79c0 7.419 7.392 15.142 9.778 17.444 2.391-2.3 9.779-10.025 9.779-17.444a9.765 9.765 0 0 0-9.779-9.73 9.769 9.769 0 0 0-9.778 9.73zm4.223 0a5.568 5.568 0 0 1 5.556-5.564 5.566 5.566 0 0 1 5.555 5.564 5.562 5.562 0 0 1-5.555 5.56 5.564 5.564 0 0 1-5.557-5.56zm2.226 0a3.346 3.346 0 0 0 3.33 3.352 3.347 3.347 0 0 0 3.334-3.352 3.345 3.345 0 0 0-3.334-3.352 3.343 3.343 0 0 0-3.331 3.352z" transform="translate(11128.999 202.998)" fill={props.fill}/></svg>
)

AddressIcon.defaultProps = {
	fill: "#9b9ea2"
}

export default AddressIcon