import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import HeroBg from "../components/Image/IndustryHeroBg"
import {
  Section,
  BreadCrumb,
  Background,
  TwoGridWrap,
  LeftWrap,
  SectionPageTitle,
  Container,
} from "../components/SectionTags"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"
import AddressIcon from "../components/Icons/AddressIcon"
import PhoneIcon from "../components/Icons/PhoneIcon"
import EmailIcon from "../components/Icons/EmailIcon"
import ClockIcon from "../components/Icons/ClockIcon"

const MapContainer = styled.div`
  height: 280px;
  ${BreakpointUp.sm`
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
  `}
  .embed-responsive{
    height: 100%;
  }
`

const TwoGrid = styled.div`
  ${BreakpointUp.xl`
		padding-top:60px;
		padding-bottom:60px;
	`}
`

const LeftGradient = styled.div`
  position: absolute;
  z-index: 3;
  width: 30%;
  left: -1px;
  top: 0;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(240, 241, 243, 1),
    rgba(240, 241, 243, 0.68),
    rgba(240, 241, 243, 0)
  );
`
const AddressItem = styled.div`
  padding-bottom:30px;
  font-size: 20px;
  line-height: 28px;
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  color: #000;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  & u {
    &:hover {
      text-decoration: none;
    }
  }
  & a {
    color: #000;
    &:hover {
      color: #0059b2;
    }
  }
  .icon {
    + .text {
      margin-left: 20px;
    }
    &.clock{
      svg{
        width:28px;
        height:28px;
      }
    }
  }
`
const ContactSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${BreakpointUp.lg`
		flex-wrap: nowrap;
	`}
`
const ContactUsImg = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 30px;
  ${BreakpointUp.lg`
		width: 50vw;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
		margin-bottom:0;
	`}
  .gatsby-image-wrapper {
    width: 100%;
    height: 40vw;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 650px;
  }
`
const GradientBackground = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(0, 89, 178, 0.1), rgba(0, 89, 178, 0.7));
  ${BreakpointUp.lg`
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	`}
`
const FormWrap = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
		width: 50vw;
		padding-left: 115px;
		padding-right: calc(50vw - 675px);
	`}
  @media(max-width: 1600px) {
    padding-right: 115px;
  }
  @media (max-width: 1500px) {
    padding: 0 5%;
  }
  @media (max-width: 1440px) {
    padding: 0 2.5%;
  }
 
  iframe {
    border: 0;
    width: 100%;
    height: 350px;
  }
`

class ContactUs extends React.Component {
  render() {
    const pageData = this.props.data.contentfulContactUsPage
    const emergencyCtaData = pageData.emergencyCta
    const estimateCtaData = pageData.estimateCta
    return (
      <Layout>
        <Seo title={pageData.metaTitle}
        description={
          pageData.metaDescription.metaDescription
        }  />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section bgColor="rgba(240, 241, 243, 1)" xpt="120px" xpb="0" mpt="120px" mpb="0" pt="90px" pb="0">
          <Background width="55vw" bg="rgba(240, 241, 243, 1)"><HeroBg /></Background>
          <Container>
            <BreadCrumb zIndex="20">
              <Link to="/"><span>Home</span></Link> {pageData.title}
            </BreadCrumb>
            <TwoGrid>
              <TwoGridWrap zIndex="20">
                <LeftWrap width="61%">
                  <SectionPageTitle mb="40px">{pageData.heroTitle}</SectionPageTitle>
                  <AddressItem>
                    <span className="icon">
                      <AddressIcon fill="#0059B2" />
                    </span>
                    <span className="text">{pageData.address}</span>
                  </AddressItem>
                  <AddressItem>
                    <span className="icon">
                      <AddressIcon fill="#0059B2" />
                    </span>
                    <span className="text">2875 N. Berkeley Lake Rd, Suite 1 Duluth GA 30096</span>
                  </AddressItem>
                  <AddressItem>
                    <span className="icon">
                      <PhoneIcon fill="#0059B2" />
                    </span>
                    <span className="text">
                      <a href="tel:8663861001">866-386-1001</a>
                    </span>
                  </AddressItem>
                  <AddressItem>
                    <span className="icon">
                      <EmailIcon fill="#0059B2" />
                    </span>
                    <span className="text">
                      <u>
                        <a href="mailto:sales@aesintl.com">{pageData.email}</a>
                      </u>
                    </span>
                  </AddressItem>
                  <AddressItem>
                    <span className="icon clock">
                      <ClockIcon fill="#0059B2" />
                    </span>
                    <span className="text">
                      Monday - Friday: 8AM–5PM<br />
                      Saturday - Sunday: Closed
                    </span>
                  </AddressItem>
                </LeftWrap>
              </TwoGridWrap>
            </TwoGrid>
          </Container>
          <MapContainer>
            <LeftGradient />
            {/* <GatsbyImage image={getImage(pageData.map)} alt="AES" /> */}
            <div className="embed-responsive">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12825.90534685552!2d-80.592186!3d36.518501!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xabaf85b7ed28b8d4!2sAES%20-%20Advanced%20Electronic%20Services%20Inc!5e0!3m2!1sen!2sus!4v1643282812062!5m2!1sen!2sus" allowfullscreen="" loading="lazy" title="map"></iframe>
            </div>
          </MapContainer>
          
        </Section>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="40px" pb="40px">
          <ContactSection>
            <ContactUsImg>
              <GradientBackground />
              <GatsbyImage
                image={getImage(pageData.contactUsForm.image)}
                alt="AES"
              />
            </ContactUsImg>
            <FormWrap>
              <iframe src="https://www.cognitoforms.com/f/7qxSu5WtzEeoHIw8Fz5hfw/3" title="Cognito Forms"></iframe>
            </FormWrap>
          </ContactSection>
        </Section>
        <EmergencyEstimateCTA
          leftData={emergencyCtaData}
          rightData={estimateCtaData}
        />
      </Layout>
    )
  }
}

export default ContactUs

export const pageQuery = graphql`
  query ContactUsPageQuery {
    contentfulContactUsPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      heroTitle
      address
      phone
      email
      map {
        gatsbyImageData(quality: 100)
      }
      contactUsForm {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(quality: 100)
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`
